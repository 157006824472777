@mixin hook-badge() {
  padding-top: 2px !important;
}

.ws-cart-itemcount-badge {
  background-color: $global-color-ci-schwarz !important;
  margin-left: -21px;
  margin-bottom: -19px;
  border: 2px solid $global-color-ci-weiss;
}

.ws-badge, .ws-badge-container, .ws-hint-badge {
  /* border-radius: $global-border-radius-small 0 0 $global-border-radius-small; */
  padding-top: 3px;
  padding-bottom: 0;
  padding-left: $global-small-padding;
  padding-right: $global-small-padding;
  font-size: $global-small-font-size;
  font-weight: $global-font-weight-medium;
}

.ws-badge {
  background-color: $global-color-ci-hellgrau;
  height: $height-badge;
}

.ws-badge-container {
  color: var(--contrast-color);
}
.ws-card-badge {
  position: absolute;
  top: $global-small-gutter;
  right: 0;
  z-index: 1;
  padding: $global-small-padding $global-medium-padding;
  border-radius: $global-border-radius 0 0 $global-border-radius;
}
.ws-card-badge, .ws-card-badge-left, .ws-card-badge-right {
  font-size: 1.5em;
}
.ws-card-badge-left {
  padding: round($global-small-padding*0.5) $global-medium-padding $global-small-padding $global-gutter;
  border-radius: 0 $global-border-radius $global-border-radius 0;
  display: inline-block;
}
.ws-card-badge-right {
  padding: 5px $global-medium-padding 11px $global-medium-padding;
  border-radius: $global-border-radius 0 0 $global-border-radius;
}
.ws-hint-badge-container {
  position: absolute;
  top: $global-small-gutter;
}
.ws-hint-badge {
  border-radius: 0 $global-border-radius-medium $global-border-radius-medium 0;
  padding: $global-xsmall-padding $global-small-padding;
  font-size: $global-small-font-size;
  font-weight: $global-font-weight-bold;
  color: $global-color;
  text-transform: uppercase;
  display: inline;
  @include boxShadowBadgeLeft();
}
.ws-hint-badge-new {
  background-color: $global-marker-new-color;
}

.ws-hint-badge-top {
  background-color: $global-marker-top-color;
}