@mixin hook-slidenav(){
  transition: color 0.1s ease-in-out;
  polyline {
    stroke-width: 3 !important;
  }
}

.ws-nav-arrow svg {
  width: 30px;
  height: 40px;
}