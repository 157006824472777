@mixin blurBackground() {
  backdrop-filter: blur($blur-amount);
  -webkit-backdrop-filter: blur($blur-amount);
}

@mixin cardStyles() {
  border-radius: $global-border-radius;
  @include boxShadowCard();
}

@mixin boxShadowCard() {
  box-shadow: 0px 0px 15px #0000001D;
}

@mixin boxShadowBadgeLeft() {
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.4);
}

@mixin borderRadiusSmall() {
  border-radius: $global-border-radius-small;
}

@mixin insetShadow($blur: 10px) {
  box-shadow: 0px 0px $blur rgba(0,0,0,0.3) inset !important;
}
