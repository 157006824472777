@mixin hook-dotnav-item() {
  display: block;
  box-sizing: border-box;
  overflow: unset !important;
  white-space: unset !important;
  text-indent: unset !important;
}

.ws-dotnav-card {
  //border-bottom: $width-badge solid transparent;
  //display: inline;
  //box-sizing: content-box;
}

.ws-dotnav-card {
  & > .uk-card-media-top img {
    border-radius: $global-border-radius;
  }
}

.uk-active .ws-dotnav-card  {
  .uk-card-media-top img {
    border-radius: $global-border-radius $global-border-radius 0 0;
  }
  border-bottom: $width-badge solid var(--bar-color);
}