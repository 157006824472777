@mixin hook-form() {
  border-radius: $global-border-radius;
  @include insetShadow();
  font-weight: $global-font-weight-medium;
  font-size: $global-xlarge-font-size;
}

@mixin hook-form-focus() {
  box-shadow: none !important;
}

@mixin hook-form-label() {
  font-weight: $global-font-weight-medium;
  padding-left: $global-xsmal-margin;
  cursor: pointer;
}

@mixin hook-form-radio() {
}

@mixin hook-inverse-form-radio() {
  border-radius: $global-border-radius-medium;
  @include insetShadow(4px);
}

@mixin hook-form-legend() {
  font-weight: $global-font-weight-medium;
  text-transform: uppercase;
}

input, select, button {
  font-family: $global-font-family;
}

.ws-cart-button-container {
  border-radius: $global-border-radius;

  .ws-cart-button {
    cursor: pointer;
    font-size: 1.6rem;
    color: $global-color !important;
  }
}

.ws-cart-amount-container {
  border-radius: $global-border-radius 0 0 $global-border-radius;
}
.ws-amount-button {
    cursor: pointer;
}

.ws-input-button-container {
  padding: 6px 12px !important;
  background-color: $global-color-ci-hellgrau;

  & > * {
    line-height: 1rem !important;
  }


  .ws-number-input {
    max-width: 30px;
    font-weight: $global-font-weight-bold;
    font-size: $global-2xlarge-font-size;
    padding: 0;
  }

  .ws-number-input {
    border: none !important;
    outline: none !important;
    background-color: $global-color-ci-hellgrau;
    resize: horizontal;
    padding: 2px $global-small-padding 0;
    border-radius: $global-border-radius-small;

    &.ws-w-1 {
      min-width: 15px;
      width: 15px;
    }

    &.ws-w-2 {
      min-width: 28px;
      width: 28px;
    }

    &.ws-w-3 {
      min-width: 41px;
      width: 41px;
    }

    &.ws-w-4 {
      min-width: 54px;
      width: 54px;
    }

    &:hover, &:active, &:focus {
      background-color: $global-color-ci-weiss;
    }
  }
}

.ws-cart-button-container:hover .ws-number-input {
  background-color: $global-color-ci-weiss;
}
.uk-input.ws-input-light:focus {
  @include insetShadow(4px);
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
input.uk-input.ws-input-error {
  color: $global-color-danger !important;
  border-left: $global-border-with-default solid $global-color-danger !important;
}