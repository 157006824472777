.ws-bar-color {
  color: var(--bar-color) !important;
}

.ws-color-flag {
  width: $width-badge;
  max-width: $width-badge;
  height: $height-badge;
}
.ws-color-flag-default {
  background-color: $global-color-primary;
}

.ws-color-tab {
  border-left-width: $width-badge;
  border-left-style: solid;
  border-left-color: var(--bar-color);
}

.ws-head-image-container {
  height: $height-standard-height;
  @media (min-width: $breakpoint-medium) {
    height: $height-medium-height;
  }
}

.ws-category-up-link-container {
  padding-left: $global-medium-padding !important;
  margin-left: 0 !important;
}

.ws-nav-current-category, .ws-nav-subcategory, .ws-category-up-link-container, .ws-footer-nav, .ws-nav-item-container {
  font-size: $global-xlarge-font-size;
}

.ws-nav-current-category, .ws-nav-subcategory, .ws-category-up-link-container, .ws-footer-nav, .ws-nav-item-container {
  font-weight: $global-font-weight-medium;
}

.ws-nav-subcategory, .ws-badge, .ws-category-up-link-container a {
  color: $global-color;
}

.ws-nav-subcategory, .ws-color-tab-next {
  padding-left: $global-gutter - $width-badge !important;
}

.ws-category-up-link-container {
  &:hover {
    background-color: $global-color-ci-hellgrau;
  }
}

[class*='ws-category-color'] {
  &:hover:not(.ws-contrast) {
    .ws-nav-subcategory, .ws-badge {
      color: white !important;
    }
  }

  &:hover {
    .ws-badge {
      background-color: transparent;
    }
  }
}

.ws-current-category-container > div {
  background-color: var(--bar-color);
  color: var(--contrast-color);
}

.ws-category-color:hover {
  background-color: var(--bar-color);
}

.ws-menu-dropdown {
  @include cardStyles();
}

.ws-hr-cart-item {
  padding: $global-gutter $width-cart-item-delete $global-small-padding $global-gutter !important;
}

.ws-cart-item-delete {
  width: $width-cart-item-delete;
  background-color: $global-color-ci-schwarz;
  color: $global-color-ci-weiss !important;
  font-size: 24px;
  padding: 5px 0 0 0;
  border-left: 5px solid $global-color-ci-weiss;
  box-sizing: border-box;

  &:hover {
    background-color: $global-color-danger;
  }
}
.ws-icon-button {
  font-size: $global-button-icon-font-size !important;
  padding: 7px 20px 5px 20px !important;
  max-height: $global-button-height;
}
.ws-delete-button {
  background-color: $global-color-ci-schwarz;
  color: $global-color-ci-weiss !important;

  &:hover {
    background-color: $global-color-danger;
  }
}

.ws-cart-price-info {
  margin-right: $width-cart-item-delete;
}

.ws-cart-item-list-footer {
  padding: 0 $global-gutter $global-gutter $global-gutter !important;
}

.ws-app-link {
  cursor: pointer;
  &:hover:not(.ws-app-link-danger):not(.ws-app-link-preserve) {
    color: $global-color-primary;
  }
  &:hover.ws-app-link-danger {
    color: $global-color-danger;
  }
}

.ws-product-carousel-container {
  padding: 15px 20px 30px 20px;
  @media (min-width: $breakpoint-large) {
    padding: 15px 50px 40px 50px;
  }
}

.ws-min-height-100 {
  min-height: 100% !important;
}
.ws-cart-image img {
  max-width: 100px;
  @media (min-width: $breakpoint-small) {
    max-width: unset;
  }
}
.ws-cart-text {
  padding-left: $global-gutter;
  padding-right: $global-gutter;
  @media (min-width: $breakpoint-medium) {
    padding-left: 0;
    padding-right: 0;
  }
}
.ws-footer-nav {
  a {
    color: $global-color-ci-bright !important;
    &:hover {
      color: $navbar-nav-item-active-color !important;
    }
  }
}
.ws-text-primary-force, .uk-light .ws-text-primary-force {
  color: $global-color-primary !important;
}
.uk-active .ws-nav-item-container, .ws-nav-item-container:hover {
  background-color: $global-color-primary !important;
  color: $global-color-ci-bright !important;
}