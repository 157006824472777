$section-padding-vertical: $global-medium-padding;
$section-padding-vertical-m: $global-medium-padding;

@mixin hook-section() {
  margin-left: $global-gutter !important;
  @media (min-width: $breakpoint-large) {
    margin-left: $shop-content-margin-left-l  + $shop-bar-with !important;
  }
  @media (min-width: $breakpoint-xlarge) {
    margin-left: $shop-content-margin-left-l + $shop-bar-with-l !important;
  }
}

.uk-section:first-child {
  padding-top: 0 !important;
}