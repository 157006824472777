.ws-shop-container {
  margin-left: $global-gutter !important;
  @media (min-width: $breakpoint-large) {
    margin-left: $shop-content-margin-left-l  + $shop-bar-with !important;
  }
  @media (min-width: $breakpoint-xlarge) {
    margin-left: $shop-content-margin-left-l + $shop-bar-with-l !important;
  }
}

.ws-filter-column {
  @media (min-width: $breakpoint-medium) {
    min-width: $filter-column-width;
    max-width: $filter-column-width !important;
  }

  @media (min-width: $breakpoint-large) {
    padding-left: 0 !important;
  }
}

.ws-content-column {
  padding-left: 0 !important;
  @media (min-width: $breakpoint-medium) {
    padding-left: $shop-content-margin-left !important;

  }
  @media (min-width: $breakpoint-xlarge) {
    padding-left: $shop-content-margin-left-l !important;
  }
}

.ws-nav-container {
  @media (min-width: $breakpoint-medium) {
    margin-left: $shop-bar-with;
  }
  @media (min-width: $breakpoint-large) {
    margin-left: $shop-bar-with-l;
  }
}

#ws-shop-bar {
  background-color: var(--bar-color);
  position: fixed;
  width: $shop-bar-with;
  height: 100%;
  top: $header-height;
  @media (min-width: $breakpoint-small) {
    top: $header-height-s;
  }
  @media (min-width: $breakpoint-large) {
    top: $header-height-l;
  }
  left: 0;
  z-index: 999;
  @media (min-width: $breakpoint-medium) {
    width: $global-medium-gutter;
    left: 0;
  }
  @media (min-width: $breakpoint-large) {
    width: $shop-bar-with-l;
    left: unset;
  }
}

.ws-price-info, .ws-price-label-sum {
  font-weight: $global-font-weight-medium;
}

.ws-price-info, .ws-price-label, .ws-price-default {
  font-size: $global-2xlarge-font-size;
  line-height: 1.8rem;
}
.ws-price-info.ws-price-strike-original {
  font-size: $global-font-size;
  color: $text-danger-color;
  text-decoration: underline;
  text-underline-offset: -.35em;
}
.ws-price-label:not(.ws-price-label-sum) {
  //color: $global-color-ci-mittelgrau;
}