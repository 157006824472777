@mixin hook-modal() {
  @include blurBackground();
}
@mixin hook-modal-dialog() {
  @include boxShadowCard();
  border-radius: $global-border-radius;
}
@mixin hook-modal-header() {
  border-top-right-radius: $global-border-radius;
  border-top-left-radius: $global-border-radius;
}
@mixin hook-modal-footer() {
  border-bottom-left-radius: $global-border-radius;
  border-bottom-right-radius: $global-border-radius;
}