// FONTAWESOME
$fa-font-path: "../fontawesome/webfonts";

// BREAKPOINTS
$breakpoint-small: 640px;
$breakpoint-medium: 960px;
$breakpoint-large: 1280px;
$breakpoint-xlarge: 1600px;


// CONTAINER
$container-max-width: 1900px !default;
$container-xsmall-max-width: 690px !default;
$container-small-max-width: 760px !default;
$container-large-max-width: 1200px !default;
$container-xlarge-max-width: 1800px !default;

// FARBEN

$global-color-ci-gruen: #A5B25A;
$global-color-ci-orange: #FFA20D;
$global-color-ci-dunkelgrau: #707070;
$global-color-ci-mittelgrau: #9D9D9C;
$global-color-ci-hellgrau: #ececec;
$global-color-ci-weiss: #FFF;
$global-color-ci-schwarz: #000;

$global-color-ci-bright: $global-color-ci-weiss;
$global-color-ci-grau: #808080;
$global-color-ci-medium-hellgrau: #e0e0e0;
$global-color-ci-blau: #0065B8;

$global-color-primary: $global-color-ci-gruen;

$global-color: $global-color-ci-schwarz;
$global-emphasis-color: $global-color-ci-dunkelgrau;
$global-muted-color: $global-color-ci-mittelgrau;
$global-inverse-color: $global-color-ci-weiss;
$inverse-global-color: $global-inverse-color;
$inverse-global-muted-color: $global-inverse-color;
$global-link-color: $global-color-ci-gruen;
$global-link-hover-color: darken($global-link-color, 5%);
$inverse-base-link-color: lighten($global-link-color, 5%);
$inverse-base-link-hover-color: lighten($inverse-base-link-color, 10%);

$base-heading-color: $global-color;

$nav-default-item-active-color: $global-color-ci-gruen;
$navbar-nav-item-color: $global-color-ci-weiss;
$nav-default-item-color: $global-color-ci-schwarz;

$global-marker-top-color: #f69647;
$global-marker-new-color: #f1f904;

$global-color-success: #9cd501;
$global-color-danger: #ED0012;

$alert-success-background: $global-color-success;
$alert-success-color: $global-color-ci-weiss;

$alert-danger-background: $global-color-danger;
$alert-danger-color: $global-color-ci-weiss;

$form-select-option-color: $global-color-ci-dunkelgrau;
$box-shadow-bottom-background: $global-color-ci-dunkelgrau;
$lightbox-background: $global-color-ci-dunkelgrau;
$lightbox-toolbar-icon-hover-color: $global-color-ci-weiss;
$lightbox-button-hover-color: $global-color-ci-weiss;

$table-row-active-background: $global-color-ci-hellgrau;
$table-hover-row-background: lighten($global-color-ci-dunkelgrau, 20%);
$table-header-cell-color: $global-color-primary;

$button-default-background: $global-color-ci-hellgrau;
$button-default-hover-background: darken($button-default-background, 5%);

$inverse-form-placeholder-color: $global-color-ci-mittelgrau;
$inverse-form-background: $global-color-ci-weiss;
$inverse-form-radio-background: $inverse-form-background;
$inverse-form-color: $global-color-ci-schwarz;
$inverse-form-focus-color: $global-color-ci-schwarz;

$inverse-button-primary-background: $global-color-ci-gruen;
$inverse-button-primary-color: $global-color-ci-weiss;
$inverse-button-primary-hover-background: darken($inverse-button-primary-background, 5%);
$inverse-button-primary-hover-color: darken($inverse-button-primary-color, 5%);


$tooltip-background: var(--bar-color);
$text-success-color: $global-color-ci-gruen;
$text-warning-color: $global-color-ci-orange;
$text-danger-color: $global-color-danger;

$base-em-color: $global-color-ci-dunkelgrau;

$search-icon-color: $global-color-ci-schwarz;

$tooltip-color: var(--contrast-color);

$pagination-item-color: $global-color-ci-schwarz;
$pagination-item-hover-color: var(--bar-color);
$pagination-item-active-color: var(--contrast-color);

$card-default-title-color: $global-color;

$button-default-color: $global-color-ci-schwarz;
$button-default-hover-color: $global-color-ci-schwarz;

$tab-item-active-color: $global-color-ci-schwarz;

// BACKGROUND
$global-background: $global-color-ci-weiss;
$global-primary-background: $global-color-primary;
$global-secondary-background: $global-color-ci-dunkelgrau;
$global-muted-background: $global-color-ci-hellgrau;
$global-success-background: $global-color-success;
$global-warning-background: $global-color-ci-orange;
$global-danger-background: $global-color-danger;
$card-hover-background: $global-color-ci-hellgrau;
$navbar-background: $global-color-ci-dunkelgrau;

$blur-amount: 20px;

// FONT
$global-base-font-size: 1rem; // 16px
$global-3xlarge-font-size: 2.1875rem; // 35px
$global-2xlarge-font-size: 1.5rem; // 26px
$global-xlarge-font-size: 1.125rem; // 18px
$global-large-font-size: 1.0rem; // 16px
$global-medium-font-size: 0.9375rem; // 15px
$global-small-font-size: 0.875rem; // 14px
$global-xsmall-font-size: 0.75rem; // 12px
$global-font-size: 16px;

$base-h2-font-size-m: 1.375rem;
$base-h3-font-size: $global-xlarge-font-size;

$form-legend-font-size: $global-2xlarge-font-size;
$form-legend-line-height: 1;

$global-font-family: 'Oxanium', sans-serif;

$global-font-weight-bold: 600;
$global-font-weight-medium: 500;
$global-font-weight-regular: 400;
$global-font-weight-light: 300;
$global-font-weight-xlight: 200;
$global-font-weight: $global-font-weight-regular;

$base-body-font-weight: 300;

$global-line-height: 1.6;

$text-large-font-size: $global-3xlarge-font-size;

$base-strong-font-weight: $global-font-weight-medium;

$breadcrumb-item-font-size: $global-large-font-size;

$button-font-size: $global-xlarge-font-size;

// GUTTER
$global-gutter: 25px;
$global-small-gutter: 12px;
$global-medium-gutter: 40px;
$global-large-gutter: 60px;

// MARGIN
$global-margin: 10px;
$section-base-margin: 25px 0;
$global-xsmal-margin: 3px;
$global-small-margin: $global-small-gutter;
$global-medium-margin: 25px;
$global-large-margin: 50px;

//PADDING
$global-padding: $global-margin;
$section-base-padding: 25px 0;
$global-xsmall-padding: 4px;
$global-small-padding: 7px;
$global-medium-padding: 15px;
$global-large-padding: 30px;
$global-list-padding-left: 18px;

$alert-padding: 8px;

// CARD
$global-category-card-max-width: 200px;
$global-category-card-picture-max-width: 125px;

$card-header-padding-horizontal: $global-gutter;
$card-header-padding-vertical:  round($global-gutter*0.7);

$card-header-padding-horizontal-l: $global-gutter;
$card-header-padding-vertical-l:  round($global-gutter*0.7);

$card-footer-padding-horizontal:  $global-gutter;
$card-footer-padding-horizontal-l:  $global-gutter;

$card-body-padding-horizontal-l: $global-gutter;
$card-body-padding-vertical: $global-gutter;
$card-body-padding-vertical-l: $global-gutter;

// HEIGHT
$height-small-height: 150px;
$height-standard-height: 200px;
$height-medium-height: 260px;
$height-large-height: 450px;

$badge-size: 20px;
$global-button-height: 50px;
$global-button-icon-font-size: 30px;

$form-radio-size: 20px;

// BORDER
$global-border: $global-color-ci-hellgrau;
$inverse-global-border: $global-color-ci-bright;
$global-border-radius-small: 3px;
$global-border-radius-medium: 6px;
$global-border-radius: 10px;
$global-border-with-default: 10px;

// LOGO
$logo-color: $global-color-ci-bright;
$logo-hover-color: darken($global-color-ci-bright, 10%);

// ANCHOR
$base-link-hover-text-decoration: none;

// MODAL
$modal-background: rgba(255, 255, 255, 0.7);
$modal-close-full-background: none;

// CLOSE
$alert-close-opacity: 1;

// DROPBAR
$dropbar-padding-top: $global-gutter;
$dropbar-padding-horizontal: $global-gutter;
$dropbar-padding-horizontal-s: $global-gutter;
$dropbar-padding-horizontal-m: $global-gutter;
$dropbar-background: rgba(67, 67, 67, 0.7);
$dropbar-color: $global-color-ci-weiss;


// WEBSEITE
$shop-bar-with: 20px;
$shop-bar-with-l: 50px;

$shop-content-margin-left: 60px;
$shop-content-margin-left-l: 90px;

$filter-column-width: 310px;
$header-height: 90px;
$header-height-s: 105px;
$header-height-l: 166px;

$width-badge: 6px;
$height-badge: 26px;

$label-text-transform: none;
$label-border-radius: $global-border-radius-medium;

$dropdown-min-width: 300px;

$breadcrumb-divider: ">";
$breadcrumb-divider-margin-horizontal: 10px;

$pagination-item-padding-vertical: 2px;

$search-navbar-icon-width: 30px;
$search-navbar-height: 30px;

$width-cart-item-delete: 55px;

// DOTNAV
$dotnav-item-width: auto;
$dotnav-item-height: auto;
$dotnav-item-border-radius: $global-border-radius-medium;
$dotnav-item-background: $global-color-ci-weiss;
$dotnav-item-hover-background: $global-color-ci-weiss;
$dotnav-item-onclick-background: $global-color-ci-weiss;
$dotnav-item-active-background: $global-color-ci-weiss;

// SLIDENAV
$slidenav-color: darken($global-color-ci-weiss, 10%);
$slidenav-hover-color: darken($global-color-ci-weiss, 20%);
$slidenav-active-color: darken($global-color-ci-weiss, 10%);
$slidenav-padding-horizontal: 16px;

  // TABLE
$table-small-cell-padding-vertical: 3px;
$table-small-cell-padding-horizontal: 5px;

$inverse-form-select-icon-color: $global-color;

// SONTIGES
$placeholder-background: darken($global-color-ci-mittelgrau, 25%);