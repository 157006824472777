.ws-width-small {
  max-width: 120px;
  @media (min-width: $breakpoint-medium) {
    max-width: 150px;
  }
  @media (min-width: $breakpoint-large) {
    max-width: 190px;
  }
  @media (min-width: $breakpoint-xlarge) {
    max-width: 210px;
  }
}