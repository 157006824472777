.ws-tab {
  &::before {
    border-bottom: none !important;
  }

  li {
    &:not(:first-child) {
      padding-left: 40px;
    }
    a {
      @include header3();
    }
  }

  & > * > a {
    border-bottom-width: 5px !important;
    padding: 3px 0 !important;
  }

  & .uk-active > a {
    border-bottom-color: var(--bar-color) !important;
  }
}