.ws-padding {
  padding: $global-gutter;
}
.ws-padding-left {
  padding-left: $global-gutter;
}
.ws-padding-left-border {
  padding-left: $global-gutter - $width-badge;
}
.ws-padding-right {
  padding-right: $global-gutter;
}
.ws-padding-bottom {
  padding-bottom: $global-gutter;
}
.ws-padding-xsmal-left {
  padding-left: $global-xsmal-margin !important;
}
.ws-padding-xsmal-right {
  padding-right: $global-xsmal-margin !important;
}
