/* latin-ext */
@font-face {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Oxanium.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Oxanium1.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Oxanium.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Oxanium1.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/Oxanium.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/Oxanium1.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/Oxanium.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/Oxanium1.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@mixin hook-base-h1() {
  font-weight: $global-font-weight-bold;
  text-transform: uppercase;
}

@mixin hook-base-h2() {
  font-weight: $global-font-weight-medium;
}

@mixin hook-base-h3() {
  font-weight: $global-font-weight-medium;
}

html, body {
  min-height: 100vh;
  position: relative;
}
body {
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 80vw;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  @media (min-width: $breakpoint-medium) {
    background-size: 70vw;
  }
  @media (min-width: $breakpoint-large) {
    background-size: 50vw;
  }
  @media (min-width: $container-xlarge-max-width) {
    background-size: 900px;
  }
}
main {
  flex: 1;
}

sup {
  font-size: 0.5em !important;
  top: -0.9em !important;
}
.ws-no-background {
  background-color: transparent !important;
  box-shadow: none !important;
}
.ws-no-wrap {
  white-space: nowrap;
}