@mixin hook-placeholder() {
  padding: $global-medium-padding $global-large-padding $global-medium-padding $global-large-padding;
  border-radius: $global-border-radius;
  border-width: 1px;
  border-color: $global-color-ci-bright;
  border-style: dashed;
  font-weight: $global-font-weight-bold;
  font-size: $global-xlarge-font-size;

  &.ws-drag-over {
    background-color: $global-color-ci-mittelgrau;
  }

}