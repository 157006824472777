.ws-icon-medium {
  font-size: $global-3xlarge-font-size;
}
.ws-placolder-icon {
  position: relative;
  top:4px;
}
.ws-placeholder-info-icon {
  position: absolute;
  right: -20px;
  top: 5px;
  cursor: pointer;
}