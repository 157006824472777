$logo-color: $global-color-ci-schwarz;

.ws-pointer {
  cursor: pointer;
}

.ws-input-icon {
  padding-right: 50px !important;
}
.ws-toggle-password {
  width: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $global-color;
  font-size: 1.3rem;
  cursor: pointer;
}