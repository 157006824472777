.uk-search-icon {
  top:12px;
}
.ws-search-input {
  height: $search-navbar-height;
  border-bottom: 2px solid $global-color-ci-schwarz !important;
  font-size: 1rem !important;
  padding-left: $global-small-padding !important;
  background-color: transparent;
}
.ws-search-form > div {
  max-height: $search-navbar-height;
}
.ws-search-form input {
  height: 21px;
}