.ws-table-striped-2 {
  & > tbody {
    & > tr:nth-child(2n+1) {
      border-top: 1px solid $global-color-ci-hellgrau;
    }
  }
}
.uk-light {
  .uk-table-divider > :first-child > tr:not(:first-child) {
    border-top: $table-divider-border-width solid rgba($table-divider-border,0.3);
  }
}