
header {
  background-color: rgba($global-color-ci-weiss, 0.7);
  min-height: $header-height;
  @media (min-width: $breakpoint-small) {
    min-height: $header-height-s;
  }
  @media (min-width: $breakpoint-large) {
    min-height: $header-height-l;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include blurBackground();
  }

  .ws-nav-container-desktop {
    .uk-logo {
      position: absolute;
      top: 10px;

      & img {
        min-width: 280px;
      }
    }
  }

  .ws-nav-container-mobile {
    .uk-logo img {
      min-width: 150px;
      @media (min-width: $breakpoint-small) {
        min-width: 180px;
      }
    }
  }

  .uk-navbar {
    margin-top: 15px;
    @media (min-width: $breakpoint-large) {
      margin-top: 23px;
    }
  }
}