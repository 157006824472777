@mixin hook-card-default() {
  @include cardStyles();
  .ws-card-headline {
    color: $global-color !important;
  }
}

@mixin hook-card-secondary() {
  @include cardStyles();
}

@mixin hook-card-default-header() {
  .ws-card-headline {
    @include header3();
  }
}
.uk-h2.ws-card-headline {
  text-transform: uppercase;
}
@mixin hook-card-media-left() {
  border-radius: $global-border-radius 0 0 $global-border-radius;
}

@mixin hook-card-media-right() {
  border-radius: 0 $global-border-radius$global-border-radius 0;
}

.ws-shop-card {
  transition: transform 150ms, box-shadow 150ms  !important;
  & > .uk-card-media-top img {
    border-radius: $global-border-radius $global-border-radius 0 0;
  }
  &:hover {
    transform: scale(1.02);
  }
}

.ws-category-card, .ws-active-card, .ws-passive-card {
  border-bottom: $global-border-with-default solid transparent;
  display: grid;
}
.ws-card-transparent {
  background-color: rgba($global-secondary-background, 0.7);
  border-radius: $global-border-radius;
  h2 {
    padding-top: $global-medium-padding;
  }
}

.ws-active-card {
  border-bottom-color: $global-color-primary;
}