$navbar-background: none;
$navbar-nav-item-color: $global-color-ci-schwarz;
$navbar-nav-item-hover-color: darken($global-color-primary, 25%);
$navbar-nav-item-onclick-color: $global-color-primary;
$navbar-nav-item-active-color: $global-color-primary;

$navbar-nav-item-font-size: 20px;
$navbar-nav-item-font-size-m: 25px;
$navbar-nav-item-font-size-l: 30px;

$navbar-nav-item-height: 1rem;

@mixin hook-navbar-nav-item() {
  font-weight: $global-font-weight-bold;
  text-transform: uppercase;
  font-size: $navbar-nav-item-font-size;
  @media (min-width: $breakpoint-small) {
    font-size: $navbar-nav-item-font-size-m;
  }
  @media (min-width: $breakpoint-large) {
    font-size: $navbar-nav-item-font-size-l;
  }
}

footer .ws-footer-nav li {
  &.uk-active > a, & > a:hover {
    color: $global-color-primary !important;
  }

  & > a {
    font-size: $navbar-nav-item-font-size;
  }

  .ws-footer-subnav {
    text-indent: $global-small-margin;

     li > a {
      font-weight: $global-font-weight-light;
      font-size: $global-small-font-size !important;
    }
  }
}


.uk-navbar-container {
  & > div.uk-container {
    padding-left: $shop-bar-with !important;
    @media (min-width: $breakpoint-large) {
      padding-left: $global-medium-gutter !important;
    }
    @media (min-width: $breakpoint-xlarge) {
      padding-left: $shop-bar-with-l !important;
    }
  }
}

.ws-nav-container {
  & > nav > div {
    min-height: 60px;
    @media (min-width: $breakpoint-small) {
      min-height: 70px;
    }
    @media (min-width: $breakpoint-large) {
      min-height: 115px;
    }
  }
}

.ws-nav-container .uk-navbar-nav > li > a {
  line-height: 1rem !important;
}

/*
.uk-navbar-center:not(:only-child) {
  top: unset !important;
  transform: translate(-50%, 5px) !important;
}
 */

.ws-nav-mobile > li {
  font-weight: $global-font-weight-bold;
  font-size: $global-2xlarge-font-size;
  text-transform: uppercase;

  .ws-nav-mobile-sub > li {
    font-size: $global-large-font-size;
  }
}

.ws-category-drop-item {
  margin-top: $global-small-gutter;
}

.ws-sub-nav-item {
  .ws-nav-subcategory.ws-active {
    color: $navbar-nav-item-active-color;
  }

  &:hover {
    background-color: $navbar-nav-item-active-color;

    .ws-nav-subcategory {
      color: $global-color-ci-bright;
    }
  }
}